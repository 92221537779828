import React from "react";

const HistoryAlarmContainer = Loader.loadBusinessComponent(
  "MonitorHistoryAlarm",
  "HistoryAlarmContainer"
);

const searchDataInit = {
  startTime: undefined,
  endTime: undefined,
  timeType: undefined,
  alarmOperationType: undefined,
  geoAddress: undefined,
  cameraIds: [],
  taskIds: undefined,
  installationSites: undefined,
  noInstallationSites: undefined,
  libIds: [],
  // sort: 1,
  captureUids: "",
  offset: 0,
  limit: 40,
  alarmTypes:["2"]
};

const searchList = [
  "AlarmStateSelect",
  "AlarmSiteScreening",
  "AlarmTimeRadio"
]

const OutsiderHistoryAlarm = () => (
  <HistoryAlarmContainer 
    libType={2}
    moduleName='outsiderHistory'
    detailModuleName='outsiderDetail'
    searchDataInit={searchDataInit}
    searchList={searchList}
    cardType='ForeignCard'
    cardStyle={{
      itemWidth: 174,
      itemHeight: 328,
      pdWidth: 120,
    }}
    taskType='101502'
    showSearchInput={false}
  />
)

export default OutsiderHistoryAlarm;
